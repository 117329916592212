import { http } from "@service/http.service";
import { useQuery } from "react-query";
import { FLIX_PURCHASE_URL } from "@constants/api-constansts";

const getFlixPurchaseHistory = (nextPage: number) => {
  return http.get({
    url: `${FLIX_PURCHASE_URL}`,
    params: { page: nextPage, limit: 20 },
  });
};

export const useFlixPurchaseHistory = (nextPage: number) => {
  const response = useQuery(
    ["PURCHASE_FLIX_HISTORY", nextPage],
    () => getFlixPurchaseHistory(nextPage),
    {
      keepPreviousData: true,
    }
  );

  return response;
};
