import { http } from "@service/http.service";
import { useQuery } from "react-query";
import { COIN_BALANCE_URL } from "@constants/api-constansts";

const getCoinBalance = () => {
  return http.get({
    url: `${COIN_BALANCE_URL}`,
  });
};

export const useCoinBalance = () => {
  const response = useQuery(["COINS_BALANCE"], getCoinBalance);
  return response;
};
