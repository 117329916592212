import React from "react";
import coinIcon from "@assets/coin-package-icons/coin-icon.png";

interface CoinCardProps {
  coins: any;
  price: any;
}

const CoinCards: React.FC<CoinCardProps> = ({ coins, price }) => {
  return (
    <article className="flex flex-col md:flex-row  px-2  gap-4 w-full  bg-bgLightPink  rounded-lg">
      <section className="flex gap-4 w-full md:w-2/3">
        <figure className="flex-shrink-0">
          <img
            className="rounded-full w-8 h-8 md:w-8 md:h-8 object-cover"
            alt="user"
            src={coinIcon}
          />
        </figure>
        <div className="flex flex-col gap-3 flex-grow ">
          <div className="inline-flex flex-grow  px-2  rounded-lg text-xs font-semibold  ">
            <div className="bg-yellow-300 p-1 px-2 rounded-lg">
              {coins} COiNS
            </div>
          </div>
          <div className="flex flex-wrap flex-grow gap-1">
            <span className="text-primary text-xs ml-2  ">AED {price}</span>
            {/* <span className="text-gray-500 text-xs line-through">
              AED {price}
            </span>
            <span className="text-primary text-xs">20%</span> */}
          </div>
        </div>
      </section>
    </article>
  );
};

export default CoinCards;
