import { useAuth } from '@auth/AuthProvider';
import { useEffect, useState, createContext, useContext } from 'react';
import { io } from 'socket.io-client';

const URL = process.env.REACT_APP_SOCKET_URL || '';
 
const SocketContext = createContext<any>(null);
export const SocketProvider = ({children}: any) => {
    const auth = useAuth();
    const [socket, setSocket] = useState<any>(null);
    useEffect(() => {
        if(auth && auth?.user?.access_token) {
            const socket = io(URL, {
                auth:{
                    token: `Bearer ${auth?.user.access_token}`
                },
                path: '/messej',
                transports:  ["websocket"],
                forceNew: true,
                secure: true,
                reconnection: true,
                reconnectionAttempts: 1,
                autoConnect: true
            });
            setSocket(socket)
        }
    }, [auth])
    

    return (
        <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
    )

}

export const useSocket = () => {
    const socket = useContext(SocketContext);
    useEffect(() => {
        socket?.connect();
        socket?.on("connect_error", (err: any) => {
            console.log(`connect_error due to ${err.message}`);
          });
        return () => {
          socket?.disconnect();
        };
      }, []);
   return {
    socket
   }
}