import React from "react";
import SuccessIcon from "@assets/FAILED.png";

const Failure = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center px-4">
      <div className="w-full max-w-xl lg:w-2/3 xl:w-1/2 py-16 bg-white shadow-lg flex flex-col gap-10 items-center rounded-3xl p-5 pt-12 relative">
        <div className="flex flex-col justify-center items-center gap-3 ">
          <img src={SuccessIcon} width={40} alt="" />
          <h1 className="text-xl text-red-500 font-semibold">
            Your Payment Failed!
          </h1>
        </div>
        <div className="px-5">
          <p className="text-md text-center text-white bg-red-500 rounded-xl p-5 shadow-md">
            We encountered an issue while processing your payment. Kindly review
            your payment details or try again later.
          </p>
        </div>
        <div className="bg-white  mb-6">
          <div className="grid grid-cols-2 gap-3 ">
            <div className=" border-gray-200 border-r pr-4">
              <p className="text-gray-600 font-medium">Requested Amount</p>
              <p className="text-md font-semibold text-blue-600">AED 143</p>
            </div>
            <div className="pl-4">
              <p className="text-gray-600 font-medium">Payment Reference ID</p>
              <p className="text-md font-semibold">
                [Your Transaction ID Here]
              </p>
            </div>
            <div className=" border-gray-200 border-r pr-4">
              <p className="text-gray-600 font-medium">Requested COiNS</p>
              <p className="text-md font-semibold ">20000 COiNS</p>
            </div>
            <div className="pl-4">
              <p className="text-gray-600 font-medium">Date</p>
              <p className="text-md font-semibold ">20/03/2024</p>
            </div>
          </div>
        </div>

        <div>
          <a href="" className="text-primary underline">
            Back
          </a>
        </div>
      </div>
    </div>
  );
};

export default Failure;
