import AppRoutes from "./router/Router";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.scss";
import { AuthProvider } from "./auth/AuthProvider";
import { SocketProvider } from "@socket/config";

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <SocketProvider>
          <AppRoutes />
        </SocketProvider>
      </AuthProvider>
      <ToastContainer />
    </QueryClientProvider>
  );
};

export default App;
