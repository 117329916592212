export const purchaseHistoryColumnData = [
  { name: "PURCHASED COiNS", key: "coin", width: "33%" },
  { name: "DATE", key: "date", width: "33%" },
  { name: "TIME", key: "time", width: "33%" },
];

export const purchaseFlixHistoryColumnData = [
  { name: "PURCHASED FLiX", key: "coin", width: "33%" },
  { name: "DATE", key: "date", width: "33%" },
  { name: "TIME", key: "time", width: "33%" },
];
