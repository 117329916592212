import { useAuth } from "@auth/AuthProvider";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import placeholder from "@assets/default-profile.png";

import CoinsBalance from "./coins";
import FlixBalance from "./flix";
type Props = {
  balance: any;
};
const Profile = ({ balance }: Props) => {
  const { user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleHistoryTab = (historyTab : any) => {
    if(historyTab === "flix") {
      navigate("/flix/purchase-history");
    }else {
      navigate("/coins/purchase-history");
    }
    //
  };
  return (
    <>
      <nav className="flex flex-col sm:flex-row justify-between items-center p-8 sm:px-12 md:px-20 lg:px-32 sm:gap-10">
        <div className="flex flex-col sm:flex-row gap-5 justify-center items-center sm:items-start sm:justify-between sm:w-full">
          <div className="flex  gap-5 justify-center items-center ">
            <figure>
              <img
                className="rounded-full w-16 sm:w-20 h-16 sm:h-20 object-cover bg-center"
                alt="user"
                src={user?.user?.profile?.user_profile_photo || placeholder}
              />
            </figure>
            <figcaption className="flex flex-col justify-center gap-2 text-center sm:text-left">
              <span className="flex flex-wrap font-bold text-secGray text-md">
                {user?.user?.profile?.name}
              </span>
              {(balance === "All" || balance === "flix") && <FlixBalance />}
              {(balance === "All" || balance === "coins") && <CoinsBalance />}
            </figcaption>
          </div>
          <div className="justify-center sm:justify-start sm:mt-0 mt-4">
            {balance === "flix" && (
              <Link to="/flix/purchase-history" className="text-primary">
                FLiX Purchase History
              </Link>
            )}
            {balance === "coins" && (
              <Link to="/coins/purchase-history" className="text-primary">
                COiNS Purchase History
              </Link>
            )}

            {balance ==="All" && (<div className="relative dropdown-menu">
              <figure className="flex cursor-pointer text-primary" onClick={toggleDropdown}>
                History{" "}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 15L7 10H17L12 15Z" fill="#B227C1" />
                </svg>
              </figure>
              {isOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white border shadow-lg z-50">
                  <button
                    className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 text-sm	"
                    onClick={() => handleHistoryTab("flix")}
                  >
                    FLiX Purchase History
                  </button>
                  <button
                    className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 text-sm	"
                    onClick={() => handleHistoryTab("coins")}
                  >
                    COiNS Purchase History
                  </button>
                </div>
              )}
            </div>)}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Profile;
