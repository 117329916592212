import { v1 as uuidv1 } from "uuid";

export function getUrlWithParam(url: string, params: any) {
  let newUrl = url;
  Object.keys(params).forEach((key: string) => {
    newUrl = `${newUrl.split(`:${key}`).join(params[key])}`;
  });
  return newUrl;
}

export function linkify(text: string) {
  var urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text?.replace(urlRegex, function (url) {
    return `<a href="${url}" class="text-blue-600 visited:text-purple-600" target="_blank">${url}</a>`;
  });
}

export function convertTimeStamp(dateString: string): string {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDate: string = date
    .toLocaleString("en-US", options)
    .replace(/\//g, "-");
  return formattedDate;
}
export function getTimeBasedUUID(): string {
  // Generate a time-based UUID using the v1 function from the uuid library
  const uuid = uuidv1();

  return uuid;
}

export function formatTime(datetimeString: string): string {
  const date = new Date(datetimeString);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Function to convert 24-hour format to 12-hour format
  const convertTo12HourFormat = (hours: number): number => {
    return hours % 12 || 12; // If hours is 0, return 12, else return hours modulo 12
  };

  // Function to add leading zero if needed
  const addLeadingZero = (num: number): string => {
    return num < 10 ? `0${num}` : num.toString();
  };

  const formattedHours = convertTo12HourFormat(hours);
  const formattedMinutes = addLeadingZero(minutes);

  // Determine if it's AM or PM
  const amOrPm = hours >= 12 ? "PM" : "AM";

  return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
}
