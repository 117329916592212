import React, { EventHandler, useEffect, useRef, useState } from "react";
import BackButton from "@assets/icons/back-button.png";
import KababMenu from "@assets/icons/kabab-menu.png";
import Icon from "@assets/flashat-icon.png";
import IconText from "@assets/flashat-icon-text.png";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useNavigate } from "react-router";
import { useCoinPackages } from "@hooks/coinPackages/useCoinPackages";
import { useQueryClient } from "react-query";

// const working_key = "D3C47FE6DF073D01B8AB516984063BE9";
// const accessCode = "AVTC04LE98CN80CTNC";
// const merchant_id = "45990";
// const host = "http://localhost:3000";

// const encrypt =

// const encReq = getEncryptedOrder(paymentData);

const CoinPackageHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (!(event.target as HTMLElement).closest(".dropdown-menu")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  const handleLogout = () => {
    localStorage?.removeItem("user");
    window.location.reload();
    //
  };
  return (
    <header className="flex justify-between items-center   px-5 md:px-10 h-20 border-bgray border-b-2 sticky top-0 z-50 bg-white">
      {location.pathname === "/home/coins-purchase-history" ? (
        <figure className="flex gap-2 cursor-pointer absolute ">
          <img
            src={BackButton}
            alt="back"
            width={24}
            onClick={() => {
              navigate("/home");
            }}
            className=""
          />
          <figcaption className="hidden sm:block">Buy COiNS</figcaption>
        </figure>
      ) : (
        <>
          <div className="flex gap-1 justify-center">
            <div>
              <img width={32} src={Icon} alt="" />
            </div>
            <div className="text-center pt-3">
              <img width={80} height={10} src={IconText} alt="" />
            </div>
          </div>
          <div className="relative dropdown-menu">
            <figure className="flex cursor-pointer" onClick={toggleDropdown}>
              <img src={KababMenu} width={30} alt="kabab" />
            </figure>
            {isOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border shadow-lg z-50">
                <button
                  className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={handleLogout}
                >
                  Log Out
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </header>
  );
};

export default CoinPackageHeader;
