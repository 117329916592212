import LoginForm from "../../components/LoginForm/LoginForm";




const Login: React.FC = () => {
   return (
    <>
        <LoginForm/>
    </>
   )
}

export default Login;