import React, { useEffect, useRef, useState } from "react";
import coinIcon from "@assets/coin-package-icons/coin-icon.png";
import usePaymentRequest from "@hooks/coinPackages/usePaymentRequest";
import { Id } from "react-toastify";
import { notification } from "@service/toast.service";
import moment from "moment";

interface BuyCoinCardProps {
  coins: number;
  discounted_price: number;
  price: number;
  discount_percentage: number;
}

const BuyCoinCard: React.FC<BuyCoinCardProps> = ({
  coins,
  discounted_price,
  price,
  discount_percentage,
}) => {
  const [timeZone, setTimeZone] = useState("");
  const getTimeZone = () => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(userTimeZone);
  };

  useEffect(() => {
    getTimeZone();
  }, []);
  const initialTransactionPayload = {
    payment_amount: discounted_price,
    currency: "AED",
    coins: coins,
    timezone: timeZone,
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { mutate, isLoading, isError, data, error } = usePaymentRequest();
  // useEffect(() => {
  //   let toastId: Id;
  //   if (isError) {
  //     toastId = notification.error({ message: "Something went wrong" });
  //   }
  //   return () => {
  //     if (toastId) notification.dismiss(toastId);
  //   };
  // }, [isError]);

  const handleBuyNow = (payload: any) => {
    setIsSubmitting(true);
    mutate(
      { body: payload },
      {
        onSuccess: (data) => {
          const html = data;
          if (containerRef.current) {
            containerRef.current.innerHTML = html || "";
            const form = containerRef.current.querySelector("form");
            if (form) {
              form.submit();
            } else {
              console.error("Form not found in the response");
            }
          }
        },
        onError: (error: any) => {
          notification?.error({ message: "Something went wrong" });
          setIsSubmitting(false);
        },
      }
    );
  };
  return (
    <>
      <div hidden ref={containerRef}></div>
      {(isLoading || isSubmitting) && (
        <div className="fixed inset-0 bg-gray-200 w-full h-full flex justify-center items-center z-50">
          <div className="flex h-14 w-14 items-center justify-center rounded-full bg-gradient-to-tr from-indigo-500 to-pink-500 animate-spin">
            <div className="h-9 w-9 rounded-full bg-gray-200"></div>
          </div>
        </div>
      )}
      <article className="flex flex-col md:flex-row justify-between p-4 gap-4 w-full  bg-gray-200 shadow-lg rounded-lg">
        <section className="flex gap-4 w-full md:w-2/3">
          <figure className="flex-shrink-0">
            <img
              className="rounded-full w-16 h-16 md:w-12 md:h-12 object-cover"
              alt="user"
              src={coinIcon}
            />
          </figure>
          <div className="flex flex-col gap-3 flex-grow">
            <div className="inline-flex flex-grow  px-2  rounded-lg text-lg md:text-base font-semibold  ">
              <div className="bg-yellow-300 p-1 px-2 rounded-lg">
                {coins} COiNS
              </div>
            </div>
            <div className="flex flex-wrap flex-grow gap-1">
              <span className="text-primary text-sm md:text-xs">
                AED {discounted_price}
              </span>
              <span className="text-gray-500 text-sm md:text-xs line-through">
                AED {price}
              </span>
              <span className="text-primary text-sm md:text-xs">
                {discount_percentage}%
              </span>
            </div>
          </div>
        </section>
        <section className="pt-1 md:pt-1 w-full md:w-1/3">
          <button
            onClick={() => {
              // paymentCCAvenue(initialTransactionPayload);
              handleBuyNow(initialTransactionPayload);
            }}
            className="w-full bg-primary text-secondary px-3 py-1 text-sm md:text-base rounded-xl"
          >
            BUY NOW
          </button>
        </section>
      </article>
      <form
        hidden
        id="nonseamless"
        method="post"
        name="redirect"
        action="https://secure.ccavenue.ae/transaction/transaction.do?command=initiateTransaction"
      >
        <input type="hidden" id="encRequest" name="encRequest" />
        <input type="hidden" name="access_code" id="access_code" />
        <button type="submit" className="hidden"></button>
      </form>
    </>
  );
};

export default BuyCoinCard;
