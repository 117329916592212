import { useAuth } from "@auth/AuthProvider";
import React from "react";
import { Link } from "react-router-dom";
import placeholder from "@assets/default-profile.png";
import { useCoinBalance } from "@hooks/coinPackages/useCoinBalance";

const CoinBalanceProfile: React.FC = () => {
  const { user } = useAuth();
  const { data, isSuccess } = useCoinBalance();

  return (
    <>
      <nav className="flex flex-col sm:flex-row justify-between items-center p-8 sm:px-12 md:px-20 lg:px-32 sm:gap-10">
        <div className="flex flex-col sm:flex-row gap-5 justify-center items-center sm:items-start sm:justify-between sm:w-full">
          <div className="flex  gap-5 justify-center items-center ">
            <figure>
              <img
                className="rounded-full w-16 sm:w-20 h-16 sm:h-20 object-cover bg-center"
                alt="user"
                src={user?.user?.profile?.user_profile_photo || placeholder}
              />
            </figure>
            <figcaption className="flex flex-col justify-center gap-2 text-center sm:text-left">
              <span className="flex flex-wrap font-bold text-secGray text-md">
                {user?.user?.profile?.name}
              </span>
              <span className="flex whitespace-nowrap text-md">
                <span className="text-md text-secGray">FLiX Balance:</span>
                <span className="font-semibold px-1">
                  {isSuccess && data?.result?.coin_balance}
                </span>
              </span>
              <span className="flex whitespace-nowrap text-md">
                <span className="text-md text-secGray">COiNS Balance:</span>
                <span className="font-semibold px-1">
                  {isSuccess && data?.result?.coin_balance}
                </span>
              </span>
            </figcaption>
          </div>
          <div className="justify-center sm:justify-start sm:mt-0 mt-4">
            <Link to="/home/coins-purchase-history" className="text-primary">
              COiNS Purchase History
            </Link>
          </div>
        </div>

      </nav>
    </>
  );
};

export default CoinBalanceProfile;
