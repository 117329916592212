import { Navigate } from "react-router-dom";
import { useAuth } from "@auth/AuthProvider";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export const PrivateRoute: React.FC<Props> = ({ children }: Props) => {
  const auth = useAuth();

  if (!auth.user) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};
export const PremiumRoute: React.FC<Props> = ({ children }: Props) => {
  const { user } = useAuth();
  if (!user?.user?.profile?.is_premium) {
    return <Navigate to="/home" />;
  }

  return <>{children}</>;
};
