import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "@pages/login/Login";
import { PrivateRoute, PremiumRoute } from "./ProtectedRoute";

import HomePage from "@pages/tw/home";
import Dashboard from "@pages/tw/dashboard";
import CoinPackages from "@pages/tw/coinPackages/CoinPackages";
import CoinsPurchaseHistory from "@pages/tw/coinPurchaseHistory/CoinsPurchaseHistory";
import PaymentStatus from "@pages/tw/paymentStatus/PaymentStatus";
import NotFoundPage from "@components/tw/errorPages/404";
import Redirect from "@pages/redirect/Redirect";
import Packages from "@pages/tw/packages";
import Flix from "@pages/tw/packages/flix";
import Coins from "@pages/tw/packages/coins";
import FlixPurchaseHistory from "@pages/tw/PurchaseHistory/Flix";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },

  {
    path: "/home",
    element: (
      <PrivateRoute>
        <HomePage />
      </PrivateRoute>
    ),
    // errorElement: <NotFoundPage />,
    children: [
      {
        path: "",
        element: <CoinPackages />,
      },
      {
        path: "coins-purchase-history",
        element: <CoinsPurchaseHistory />,
      },
      {
        path: "payment-status",
        element: <PaymentStatus />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
    children: [
      {
        path: "",
        element: <Packages packageType="All" />,
      }
    ],
  },
  {
    path: "/flix",
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
    children: [
      {
        path: "",
        element: <Packages packageType="flix" />,
      },
      {
        path: "purchase-history",
        element: <FlixPurchaseHistory />,
      },
      {
        path: "payment-status",
        element: <PaymentStatus />,
      },
    ],
  },
  {
    path: "/coins",
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
    // errorElement: <NotFoundPage />,
    children: [
      {
        path: "",
        element: <Packages packageType="coins" />,
      },
      {
        path: "purchase-history",
        element: <CoinsPurchaseHistory />,
      },
      {
        path: "payment-status",
        element: <PaymentStatus />,
      },
    ],
  },
  {
    path: "verify-user",
    element: <Redirect />,
  },
]);

const AppRoutes = () => <RouterProvider router={router} />;

export default AppRoutes;
