import React from "react";
import { useFlixBalance } from "@hooks/flix/useFlixBalance";

const FlixBalance: React.FC = () => {
  const { data, isSuccess } = useFlixBalance();

  return (
    <>
      <span className="flex whitespace-nowrap text-md">
        <span className="text-md text-secGray">FLiX Balance:</span>
        <span className="font-semibold px-1">
          {isSuccess && data?.result?.flax_balance}
        </span>
      </span>
    </>
  );
};

export default FlixBalance;
