import DashboardHeader from "@components/tw/common/dashboard-header";
import { Outlet } from "react-router";

const Dashboard: React.FC = () => (
  <>
    <main className="flex-1 border-bgray border-r-2 relative ">
      <DashboardHeader />
      <div>
        <Outlet />
      </div>
    </main>
  </>
);

export default Dashboard;
