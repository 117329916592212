import { formatTime } from "@util/util";
import moment from "moment";
import React from "react";
import CoinCards from "./CoinCards";

interface TableItemsProps {
  data: any; // You can be more specific if you know the exact structure
}

const TableItems: React.FC<TableItemsProps> = ({ data }) => {
  return (
    <>
      <tr className="w-full h-1"></tr>
      <tr>
        <td className=" rounded-tl-xl pl-12 rounded-bl-xl py-6 bg-bgLightPink ">
          <CoinCards coins={data?.coins} price={data?.price} />
        </td>
        <td className="p-4 py-6  bg-bgLightPink ">
          {moment?.utc(data?.time_created).local().format("DD/MM/YYYY")}
        </td>
        <td className="p-4 py-6 rounded-tr-xl rounded-br-xl bg-bgLightPink ">
          {/* {formatTime(data?.time_created)} */}
          {moment?.utc(data?.time_created).local().format("LT")}
        </td>
      </tr>
    </>
  );
};

export default TableItems;
