import { ToastPosition, toast } from "react-toastify";

function success({
  message,
  position = toast.POSITION.TOP_RIGHT,
}: {
  message: string;
  position?: ToastPosition;
}) {
  const toastId = toast.success(message, { position });
  return toastId;
}

function error({
  message,
  position = toast.POSITION.TOP_RIGHT,
}: {
  message: string;
  position?: ToastPosition;
}) {
  const toastId = toast.error(message, { position });
  return toastId;
}
function dismiss(toastId: any) {
  toast.dismiss(toastId);
}

export const notification = {
  success,
  error,
  dismiss,
};
