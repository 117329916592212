import "./LoginForm.scss";
import "../../styles/button.scss";
import logo from "../../assets/logo.svg";
import { LoginPayload, STATUS, useAuth } from "../../auth/AuthProvider";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Navigate } from "react-router-dom";
import IntlTelInput, { intlTelInput } from "intl-tel-input/react";
import "intl-tel-input/styles";

type Country = {
  areaCodes: string[] | null;
  dialCode: string;
  iso2: string;
  name: string;
};

const LoginForm: React.FC = () => {
  const initialState = {
    email: "",
    password: "",
    phone: "",
    country_code: "+1",
  };
  const auth = useAuth();
  const [activeTab, setActiveTab] = useState("email");
  const [value, setValue] = useState("");
  const [state, setState] = useState<LoginPayload>({
    email: "",
    password: "",
    phone: "",
    country_code: "+1",
  });
  const [selCountry, setSelCountry] = useState<Country | null>(null);
  const [visible, setVisible] = useState(false);
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [passwordFocus, setPasswordFocus] = useState(false);

  const handleTabClick = (tab: "email" | "mobile"): void => {
    setActiveTab(tab);
  };
  const [disableButton, setDisableButton] = useState(true);
  useEffect(() => {
    if (activeTab === "email") {
      setErrorCode(null);
      setPasswordFocus(false);
      if (!state.email || !state.password) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    } else if (activeTab === "mobile") {
      if (
        errorCode !== null ||
        !state.phone ||
        !state.country_code ||
        !state.password
      ) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    }
  }, [errorCode, state, activeTab]);

  useEffect(() => {
    setState({
      email: "",
      password: "",
      phone: "",
      country_code: "+1",
    });
  }, [activeTab]);

  // Callback to handle country change

  const countryData = useMemo(() => intlTelInput.getCountryData(), []);

  const handleCountryChange = useCallback(
    (countryIso2: string) => {
      const selectedCountry = countryData.find((c) => c.iso2 === countryIso2);

      setSelCountry(selectedCountry || null);
      const newDialCode = selectedCountry?.dialCode || "";

      // Check if dialCode is different from the current state
      if ("+" + newDialCode !== state.country_code) {
        setState((prevState) => ({
          ...prevState,
          country_code: "+" + newDialCode,
        }));
      }
    },
    [countryData, state.country_code]
  );

  const handleNumberChange = useCallback((value: string) => {
    const phoneNumber = intlTelInput?.utils?.getCoreNumber(
      value,
      selCountry?.iso2
    );
    setValue(phoneNumber || "");
  }, []);
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      phone: value,
    }));
  }, [value]);
  const handleChangeErrorCode = (errorCode: number | null) => {
    setErrorCode(errorCode);
    // You can add more logic here if needed
  };

  const handleFocus = () => {
    setPasswordFocus(true);
  };

  const onClick = () => {
    if (activeTab === "email") {
      if (!state.email || !state.password) {
        return;
      }
    } else if (activeTab === "mobile") {
      if (!state.phone || !state.country_code || !state.password) {
        return;
      }
    }
    auth.login(state);
  };

  const toggleVisibility = () => {
    setVisible(!visible);
  };
  const isLoading = auth.status === STATUS.LOADING;

  // const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = event.target;
  //   setState({ ...state, [name]: value });
  // };

  if (auth.user) {
    return <Navigate to="dashboard" />;
  }

  return (
    <>
      <div className="py-10 overflow-y-visible">
        <div className="login  ">
          <form action="">
            <div className="login__wrapper py-8">
              <div className="login__content">
                <div className="logo">
                  <img src={logo} alt="" />
                </div>
                <div className="login__form-section mt-5">
                  <h2 className="login__title">Login</h2>

                  <div className="tab mt-5">
                    <ul className="flex items-center gap-4 sm:gap-8 overflow-x-auto">
                      <li
                        className={`relative whitespace-nowrap text-lg font-bold cursor-pointer pb-2 ${
                          activeTab === "email"
                            ? "text-black transition-all duration-600 ease-out"
                            : "text-gray-500"
                        }`}
                        onClick={() => handleTabClick("email")}
                      >
                        <span
                          className={`relative py-2 ${
                            activeTab === "email"
                              ? "underline-active"
                              : "underline-inactive"
                          }`}
                        >
                          Email/UserName
                        </span>
                      </li>
                      <li
                        className={`relative whitespace-nowrap text-lg font-bold cursor-pointer pb-2 ${
                          activeTab === "mobile"
                            ? "text-black transition-all duration-600 ease-out"
                            : "text-gray-500"
                        }`}
                        onClick={() => handleTabClick("mobile")}
                      >
                        <span
                          className={`relative py-2 ${
                            activeTab === "mobile"
                              ? "underline-active"
                              : "underline-inactive"
                          }`}
                        >
                          Mobile Number
                        </span>
                      </li>
                    </ul>

                    <div className="form-wrapper">
                      {activeTab === "email" && (
                        <div className="tab__content">
                          <div className="formgroup">
                            <p className="formgroup__label text-md">
                              Email/Username
                            </p>
                            <div className="formgroup__item mt-3">
                              <input
                                type="email"
                                placeholder="Enter Email/username"
                                name="email"
                                onChange={onChangeHandler}
                                value={state.email}
                              />
                            </div>
                          </div>
                          <div className="formgroup">
                            <p className="formgroup__label text-md">Password</p>
                            <div
                              className={`formgroup__item password mt-3 ${
                                visible ? "visible" : ""
                              }`}
                            >
                              <input
                                type={visible ? "text" : "password"}
                                placeholder="Enter Password"
                                name="password"
                                onChange={onChangeHandler}
                                value={state.password}
                              />
                              <div
                                onClick={toggleVisibility}
                                className="eye-icon"
                              ></div>
                            </div>
                          </div>
                        </div>
                      )}

                      {activeTab === "mobile" && (
                        <div className="tab__content">
                          <div className="formgroup">
                            <p className="formgroup__label">Mobile Number</p>

                            <div className="formgroup">
                              <div
                                className="formgroup__item mt-2"
                                style={{ width: "100%" }}
                              >
                                <div>
                                  <IntlTelInput
                                    initOptions={{
                                      initialCountry: "us",
                                      nationalMode: true,
                                      containerClass: "",
                                      // useFullscreenPopup: true,

                                      separateDialCode: true,
                                      utilsScript:
                                        "https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.12/build/js/utils.js",
                                    }}
                                    onChangeCountry={handleCountryChange}
                                    onChangeNumber={handleNumberChange}
                                    onChangeErrorCode={handleChangeErrorCode}
                                    inputProps={{
                                      className:
                                        " border border-1 border-gray-200 px-48",
                                      style: { width: "100%" },
                                      placeholder: "Enter Mobile Number",
                                    }}
                                  />
                                </div>
                              </div>
                              {errorCode !== null && passwordFocus && (
                                <p
                                  className="w-full text-start px-2"
                                  style={{ color: "red" }}
                                >
                                  Please enter a valid mobile number
                                </p>
                              )}
                              {/* <div className="formgroup__item mt-2">
                                <input
                                  type="number"
                                  placeholder="Enter mobile number"
                                  name="phone"
                                  onChange={onChangeHandler}
                                  value={state.phone}
                                />
                              </div> */}
                            </div>
                          </div>
                          <div className="formgroup">
                            <p className="formgroup__label">Password</p>
                            <div className="formgroup__item password">
                              <input
                                onFocus={handleFocus}
                                // onBlur={handleBlur}
                                type={visible ? "text" : "password"}
                                placeholder="Enter Password"
                                name="password"
                                onChange={onChangeHandler}
                                value={state.password}
                              />
                              <div
                                onClick={toggleVisibility}
                                className="eye-icon"
                              ></div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="flex justify-center items-center ">
              <h5 className="text-primary text-sm cursor-pointer">
                FORGOT PASSWORD ?
              </h5>
            </div> */}
                <div className="login__button ">
                  <button
                    type="submit"
                    className={` btn ${
                      disableButton
                        ? "btn--primary bg-primaryLightest text-primaryLight"
                        : " btn--primary bg-primary"
                    }`}
                    onClick={onClick}
                    disabled={isLoading || disableButton}
                  >
                    {isLoading ? "LOGING IN..." : "LOGIN"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
