import { useState } from "react";

export function useLocalStorage<T>(key: string, defaultValue?: T) {
    const [storedValue, setStoredValue] = useState(() => {
       try{
          const value = localStorage.getItem(key)
          if(value) {
            const parsedValue = JSON.parse(value);
            return parsedValue;
          } else {
            localStorage.setItem(key, JSON.stringify(defaultValue));
            return defaultValue;
          }
       } catch{
         return defaultValue;
       }
    })

    const setValue = (newValue: T) => {
        try {
          window.localStorage.setItem(key, JSON.stringify(newValue));
        } catch (err) {}
        setStoredValue(newValue);
      };
     
    return [storedValue, setValue];
}