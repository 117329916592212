interface HttpReqInfo {
  url: string;
  params?: any;
  headers?: any;
}

const HTTP_STATUS = {
  UNAUTHORIZED: 401,
};

const COMMON_HEADERS = {
  "content-type": "Application/json",
};
async function get(
  { url, params, headers }: HttpReqInfo,
  config = { auth: true }
) {
  try {
    console.log("..headers", { ...headers });
    const authHeader = config.auth ? getToken() : {};
    const response = await fetch(`${url}?${new URLSearchParams(params)}`, {
      headers: { ...COMMON_HEADERS, ...authHeader, ...headers },
    });
    const json = await response.json();
    if (!response.ok) {
      throw response;
    }
    console.log("response is ", json);
    return json;
  } catch (error: any) {
    if (error.status === HTTP_STATUS.UNAUTHORIZED) {
      localStorage.removeItem("user");
      window.location.reload();
    }
    throw error;
  }
}

async function post(
  { url, headers }: HttpReqInfo,
  body: any,
  config = { auth: true, responseType: "json" }
) {
  try {
    const authHeader = config.auth ? getToken() : {};
    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...COMMON_HEADERS,
        ...authHeader,
        ...headers,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw response;
    }

    if (config.responseType === "text") {
      return await response.text();
    }

    return await response.json();
  } catch (error: any) {
    if (error.status === HTTP_STATUS.UNAUTHORIZED) {
      localStorage.removeItem("user");
      window.location.reload();
    }
    throw error;
  }
}
async function del({ url, headers }: HttpReqInfo, config = { auth: true }) {
  try {
    const authHeader = config.auth ? getToken() : {};
    const response = await fetch(`${url}`, {
      method: "DELETE",
      headers: {
        ...COMMON_HEADERS,
        ...authHeader,
        ...headers,
      },
    });

    if (!response.ok) {
      throw response;
    }

    return response;
  } catch (error: any) {
    if (error.status === HTTP_STATUS.UNAUTHORIZED) {
      localStorage.removeItem("user");
      window.location.reload();
    }
    throw error;
  }
}
async function put(
  { url, headers }: HttpReqInfo,
  body: any,
  config = { auth: true }
) {
  try {
    const authHeader = config.auth ? getToken() : {};
    const response = await fetch(`${url}`, {
      method: "PUT",
      headers: {
        ...COMMON_HEADERS,
        ...authHeader,
        ...headers,
      },
      body: JSON.stringify(body),
    });

    const json = await response.json();

    if (!response.ok) {
      throw response;
    }

    return json;
  } catch (error: any) {
    if (error.status === HTTP_STATUS.UNAUTHORIZED) {
      localStorage.removeItem("user");
      window.location.reload();
    }
    throw error;
  }
}
async function postFormData(
  { url, headers }: HttpReqInfo,
  body: FormData,
  config = { auth: true }
) {
  console.log("body", body);

  try {
    const authHeader = config.auth ? getToken() : {};

    const fetchOptions = {
      method: "POST",
      headers: {
        ...authHeader,
        ...headers,
      },
      body: body,
    };

    const response = await fetch(url, fetchOptions);
    const json = await response.json();

    if (!response.ok) {
      throw response;
    }

    return json;
  } catch (error: any) {
    if (error.status === HTTP_STATUS.UNAUTHORIZED) {
      localStorage.removeItem("user");
      window.location.reload();
    }
    throw error;
  }
}

export const http = {
  get,
  post,
  del,
  put,
  postFormData,
};

function getToken() {
  const user = localStorage.getItem("user");
  let parsedUser;
  if (user) {
    parsedUser = JSON.parse(user);
  }
  return { Authorization: `Bearer ${parsedUser?.access_token}` };
}
