
import { useEffect, useState } from "react";
import HistoryTable from "@components/tw/FlixPackage/PurchaseHistory/HistoryTable";
import { purchaseFlixHistoryColumnData } from "@constants/columns";
import { useFlixPurchaseHistory } from "@hooks/flix/useFlixPurchaseHistory";
// import { useCoinPurchaseHistory } from "@hooks/coinPackages/useCoinPurchaseHistory";
import { notification } from "@service/toast.service";
import { Id } from "react-toastify";

const FlixPurchaseHistory: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, refetch, isError } =
  useFlixPurchaseHistory(currentPage);

  useEffect(() => {
    let toastId: Id;
    if (isError) {
      toastId = notification.error({ message: "Something went wrong" });
    }
    return () => {
      if (toastId) notification.dismiss(toastId);
    };
  }, [isError]);

  useEffect(() => {
    if (data?.result && data.result.current_page) {
      setCurrentPage(data.result.current_page);
    }
  }, [data?.result, data?.result?.current_page]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refetch();
  };

  return (
    <div className="flex justify-center px-4 sm:px-6 md:px-16 lg:px-32 pt-10 relative">
      <HistoryTable
        titles={purchaseFlixHistoryColumnData}
        rowData={data?.result?.purchases || []}
        isLoading={isLoading}
        currentPage={currentPage}
        nextPage={data?.result?.next_page}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default FlixPurchaseHistory;
