import { http } from "@service/http.service";
import { useQuery } from "react-query";
import { COINS_PURCHASE_URL } from "@constants/api-constansts";

const getCoinsPurchaseHistory = (nextPage: number) => {
  return http.get({
    url: `${COINS_PURCHASE_URL}`,
    params: { page: nextPage, limit: 20 },
  });
};

export const useCoinPurchaseHistory = (nextPage: number) => {
  const response = useQuery(
    ["PURCHASE_HISTORY", nextPage],
    () => getCoinsPurchaseHistory(nextPage),
    {
      keepPreviousData: true,
    }
  );

  return response;
};
