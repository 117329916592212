import CoinPackageHeader from "@components/tw/common/coinPackageHeader/CoinPackageHeader";
import { Outlet } from "react-router";

const HomePage: React.FC = () => (
  <>
    <main className="flex-1 border-bgray border-r-2 relative ">
      <CoinPackageHeader />
      <div>
        <Outlet />
      </div>
    </main>
  </>
);

export default HomePage;
