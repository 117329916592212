import { VERIFY_TOKEN_FROM_MOB_APP_URL } from "@constants/api-constansts";
import { notification } from "@service/toast.service";
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "../../auth/AuthProvider";

const Redirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const [toastId, setToastId] = useState<string | number>("");
  const errorNotifiedRef = useRef(false); // Use a ref to track error notification

  const query = new URLSearchParams(location.search);
  const accessToken = query.get("access_token");
  var packageType = query.get("packageType");

  useEffect(() => {
    if (accessToken && !errorNotifiedRef.current) {
      verifyToken();
    }
  }, [accessToken]);

  const verifyToken = async () => {
    try {
      const response = await fetch(`${VERIFY_TOKEN_FROM_MOB_APP_URL}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to verify token");
      }

      const responseData = await response.json();
      auth.updateUser({
        access_token: accessToken,
        user: responseData,
      });
    } catch (error) {
      localStorage.removeItem("user");
      navigate("/");

      if (!errorNotifiedRef.current) {
        errorNotifiedRef.current = true; // Set the ref to true
        const toastID = notification.error({
          message: "Authentication Failed",
        });
        setToastId(toastID); // Store the toast ID
      }
    }
  };

  useEffect(() => {
    if (auth.user) {
      console.log("here", packageType)
      if(packageType === "flix") {
        navigate("/flix");
      } else if(packageType === "coins") {
        navigate("/coins");
      } else {
        navigate("/dashboard");
      }
    }
  }, [auth.user]);

  useEffect(() => {
    if (!accessToken) {
      navigate("/");
    }
  }, [accessToken, navigate]);

  return (
    <div className="fixed inset-0 bg-gray-200 w-full h-full flex justify-center items-center z-50">
      <div className="flex h-14 w-14 items-center justify-center rounded-full bg-gradient-to-tr from-indigo-500 to-pink-500 animate-spin">
        <div className="h-9 w-9 rounded-full bg-gray-200"></div>
      </div>
    </div>
  );
};

export default Redirect;
