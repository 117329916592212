import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { LOGIN_URL } from "../constants/api-constansts";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { notification } from "../service/toast.service";
const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
  IDLE: "idle",
} as const;

type StatusKeys = keyof typeof STATUS;
type Status = typeof STATUS[StatusKeys];

export interface User {
  user: {
    id: number;
    profile: {
      about: string;
      gender: string;
      name: string;
      user_profile_photo: string;
      thumbnail: string;
      is_premium: boolean;
    } | null;
    username: string;
  };

  access_token: string | null;
}

interface Auth {
  status: Status;
  user: User | null;
  login: (payload: LoginPayload) => void;
  updateUser: (newUser: User) => void;
}

export interface LoginPayload {
  email: string;
  password: string;
  phone: string;
  country_code: string;
}

async function login(params: LoginPayload) {
  try {
    const response = await fetch(LOGIN_URL, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "content-type": "Application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (error) {
    throw error;
  }
}

const AuthContext = createContext<Auth>({
  user: null,
  status: "idle",
  login: () => {},
  updateUser: () => {},
});

const AuthProvider = ({ children }: { children: any }) => {
  const [status, setStatus] = useState<Status>("idle");
  const [user, setStorage] = useLocalStorage<User | null>("user", null);

  const mutation = useMutation(login, {
    onSuccess: (response: any) => {
      const { user, ...rest } = response.result;
      setStorage(response.result);
      setStatus(STATUS.SUCCESS);
    },
    onError: (error: any) => {
      console.log("err", error);
      const { message }: { message: string } = error;
      notification.error({ message: message || "Something went wrong!" });
      setStatus(STATUS.ERROR);
    },
  });

  const loginHandler = (payload: LoginPayload) => {
    setStatus(STATUS.LOADING);
    mutation.mutate(payload);
  };
  const updateUser = (newUser: User) => {
    setStorage(newUser);
  };

  const value = useMemo(
    () => ({ user, login: loginHandler, status, updateUser }),
    [user, status]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth() {
  const auth = useContext(AuthContext);
  if (!auth) {
    throw Error("useAuth hook should be used inside an Auth Provider");
  }
  return auth;
}

export { useAuth, AuthProvider, STATUS };
