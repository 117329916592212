import { PAYMENT_REQUEST_URL } from "@constants/api-constansts";
import { http } from "@service/http.service";
import { getUrlWithParam } from "@util/util";
import { useMutation } from "react-query";

function initiatePayment({ body }: { body: any }) {
  const url = getUrlWithParam(`${PAYMENT_REQUEST_URL}`, "");
  return http.post(
    { url, headers: { "Content-Type": "application/json" } },
    body,
    { auth: true, responseType: "text" }
  );
}

const usePaymentRequest = () => {
  const mutation = useMutation(initiatePayment);
  return mutation;
};

export default usePaymentRequest;
