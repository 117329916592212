import React from "react";
import TableRowShimmer from "@components/tw/shimmers/TableRowShimmer";
import TableHeader from "./TableHeader";
import TableItems from "./TableItems";
import Pagination from "../Pagination";

interface TableProps {
  titles: { name: string; key: string; width: string }[];
  rowData: any[];
  isLoading: boolean;
  currentPage: number;
  nextPage: boolean;
  onPageChange: (page: number) => void;
}

const tableContainerHeight = "80vh";

const HistoryTable: React.FC<TableProps> = ({
  titles,
  rowData,
  isLoading,
  currentPage,
  nextPage,
  onPageChange,
}) => {
  return (
    <div className="w-full flex flex-col">
      <div
        className="table-container w-full"
        style={{ height: tableContainerHeight }}
      >
        <table className="w-full">
          <TableHeader titles={titles} />
          <tbody>
            {isLoading ? (
              <>
                <TableRowShimmer />
              </>
            ) : rowData?.length > 0 ? (
              rowData.map((item, index) => (
                <TableItems key={index} data={item} />
              ))
            ) : (
              <tr className="h-96">
                <td
                  colSpan={titles.length}
                  className="text-center py-4 text-2xl font-bold"
                >
                  No records to display.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center items-center p-4">
        {rowData.length > 0 && (
          <Pagination
            currentPage={currentPage}
            nextPage={nextPage}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
};

export default HistoryTable;
