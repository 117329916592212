import React, { lazy, Suspense } from "react";
import infoIcon from "@assets/coin-package-icons/info.png";
import Profile from "@components/tw/common/profile";
const Coins = React.lazy(() => import("./coins"));
const Flix = React.lazy(() => import("./flix"));
type Props = {
  packageType: any;
};
const Packages = ({ packageType }: Props) => {
  console.log("here tyep", packageType);
  return (
    <div>
      <section className="flex flex-col ">
        <Profile balance={packageType} />
        <div className="flex flex-col md:flex-row items-start md:items-center">
          <div className="pl-4 md:pl-32 font-semibold text-lg pt-4 md:pt-16">
            Recharge :
          </div>
          <div className="flex justify-center items-center gap-2	font-bold text-xl text-red-500 px-4 md:px-2 pt-4 md:pt-16">
            Save up to 25% with a lower third-party service fee.<img width={20} height={20} src={infoIcon} alt="info" />
          </div>
        </div>

        <Suspense fallback={<div>Loading...</div>}>
          {(packageType == "All" || packageType == "flix") && <Flix />}
          {(packageType == "All" || packageType == "coins") && <Coins />}
        </Suspense>
      </section>
    </div>
  );
};

export default Packages;
