import React from "react";
import { useNavigate } from "react-router";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/home");
  };

  return (
    <div
      style={{
        height: "100vh",
      }}
      className="flex flex-col justify-center items-center bg-gradient-to-b from-fuchsia-500 to-amber-400 relative"
    >
      <div
        style={{
          height: "60vh",
        }}
        className="w-full flex justify-center items-end  t text-center "
      >
        <div className="flex justify-center">
          <h1 className="text-9xl sm:text-9xl md:text-9xl lg:text-10xl xl:text-10xl text-white font-extrabold ">
            404
          </h1>
        </div>
      </div>
      <div
        style={{
          height: "60vh",
        }}
        className="w-full  px-4  flex flex-col gap-6 sm:gap-10 items-center py-12 sm:py-24 text-center "
      >
        <div>
          <h1 className="text-3xl sm:text-5xl text-primary font-medium">
            Sorry, Page Not Found
          </h1>
        </div>
        <div>
          <h1
            style={{
              fontFamily: "monospace",
              fontWeight: "bold",
            }}
            className="text-2xl sm:text-2xl"
          >
            The page you requested could not be found
          </h1>
        </div>
        <div>
          <button
            onClick={handleGoBack}
            className="bg-primary text-white hover:bg-secondary hover:text-primary text-md sm:text-lg font-semibold py-3 px-6 sm:py-4 sm:px-8 rounded-2xl"
          >
            GO BACK
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
