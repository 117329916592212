import { COIN_PACKAGES_URL } from "@constants/api-constansts";
import { CoinPackageModal } from "@models/coinPackages/coinPackages";
import { HttpResponse } from "@models/http-resposne";
import { http } from "@service/http.service";
import { useQuery } from "react-query";

interface PackagesResponse {
  result: CoinPackageModal[];
}

const getCoinPackages = () => {
  return http.get({ url: `${COIN_PACKAGES_URL}` });
};

export const useCoinPackages = () => {
  const response = useQuery<HttpResponse<CoinPackageModal[]>>(
    "GET_COIN_PACKAGES",
    getCoinPackages
  );

  return response;
};
