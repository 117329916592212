import { useAuth } from "@auth/AuthProvider";
import React from "react";
import { Link } from "react-router-dom";
import placeholder from "@assets/default-profile.png";
import { useCoinBalance } from "@hooks/coinPackages/useCoinBalance";

const CoinsBalance: React.FC = () => {
  const { data, isSuccess } = useCoinBalance();

  return (
    <>
      <span className="flex whitespace-nowrap text-md">
        <span className="text-md text-secGray">COiNS Balance:</span>
        <span className="font-semibold px-1">
          {isSuccess && data?.result?.coin_balance}
        </span>
      </span>
    </>
  );
};

export default CoinsBalance;
