import Cancel from "@components/tw/common/payment/Cancel";
import Failure from "@components/tw/common/payment/Failure";
import Success from "@components/tw/common/payment/Success";
import React from "react";

const PaymentStatus = () => {
  return (
    <div>
      <Failure />
      {/* <Success /> */}
      {/* <Cancel /> */}
    </div>
  );
};

export default PaymentStatus;
