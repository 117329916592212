import CoinBalanceProfile from "@components/tw/coinPackages/coinBalanceProfile/CoinBalanceProfile";
import BuyCoinCard from "@components/tw/coinPackages/buyCoinCards/buyCoinCard";
// import CoinCardShimmer from "@components/tw/shimmers/CoinCardShimmer";
import { useCoinPackages } from "@hooks/coinPackages/useCoinPackages";
import { API_STATUS } from "@constants/api-constansts";
import { CoinPackageModal } from "@models/coinPackages/coinPackages";
import CoinCardShimmer from "@components/tw/shimmers/CoinCardShimmer";
import { Id, toast } from "react-toastify";
import { useEffect } from "react";
import { notification } from "@service/toast.service";
import infoIcon from "@assets/coin-package-icons/info.png";
const CoinPackages: React.FC = () => {
  const { data, isLoading, status, isError, error } = useCoinPackages();
  console.log("data", data?.result);

  useEffect(() => {
    let toastId: Id;
    if (isError) {
      toastId = notification.error({ message: "Something went wrong" });
    }
    return () => {
      if (toastId) notification.dismiss(toastId);
    };
  }, [isError]);
  return (
    <>
      <section className="flex flex-col ">
        <CoinBalanceProfile />
        <div className="flex flex-col md:flex-row items-start md:items-center">
          <div className="flex items-center">
            <h2 className="pl-4 md:pl-32 font-semibold text-lg pt-4 md:pt-16">
              Recharge
            </h2>
            <span className="font-semibold text-lg pt-4 md:pt-16 md:ml-2">
              :
            </span>
          </div>
          <h2 className="font-bold text-xl text-red-500 px-4 md:px-2 pt-4 md:pt-16">
            Save up to 25% with a lower third-party service fee.
          </h2>
          <div className="pt-2 md:pt-16 pl-4 md:pl-0">
            <img width={20} height={20} src={infoIcon} alt="info" />
          </div>
        </div>
        <div>
          <h2 className="px-4 md:px-32 font-bold text-lg  pt-12">
            COiNS Package
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-10 px-4 md:px-32 py-10 ">
          {isLoading && (
            <>
              <CoinCardShimmer />
              <CoinCardShimmer />
              <CoinCardShimmer />
              <CoinCardShimmer />
              <CoinCardShimmer />
              <CoinCardShimmer />
              <CoinCardShimmer />
              <CoinCardShimmer />
              <CoinCardShimmer />
              <CoinCardShimmer />
              <CoinCardShimmer />
              <CoinCardShimmer />
              <CoinCardShimmer />
              <CoinCardShimmer />
              <CoinCardShimmer />
            </>
          )}
          {status === API_STATUS.SUCCESS &&
            data?.result &&
            data?.result?.length > 0 &&
            data?.result?.map((item: CoinPackageModal) => {
              return (
                <BuyCoinCard
                  key={item?.id}
                  coins={item?.coins}
                  price={item?.price}
                  discounted_price={item?.discounted_price}
                  discount_percentage={item?.discount_percentage}
                />
              );
            })}
        </div>
      </section>
    </>
  );
};

export default CoinPackages;
