import { http } from "@service/http.service";
import { useQuery } from "react-query";
import { FLIX_BALANCE_URL } from "@constants/api-constansts";

const getFlixBalance = () => {
  return http.get({
    url: `${FLIX_BALANCE_URL}`,
  });
};

export const useFlixBalance = () => {
  const response = useQuery(["FLIX_BALANCE"], getFlixBalance);
  return response;
};
